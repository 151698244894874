



/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


.red-text {
    color : #d93128;
}
.sag{
   color: #ffb12a !important;
}
.sagbg{
    background-color: #ffb12a !important;
 }
.my2{
    color: #0f7eee;
}
.my2bg{
    background-color: #0f7eee;
}
.my3{
    color: #102950;
}
.my3bg{
    background-color: #102950;
}
.my-primary{
    background-color: #ffb12a !important;
    color : #d93128 !important;
    p {
        color: #000 !important;
        font-weight: bold !important;
      }
}
p {
    color: #000 !important;
    font-weight: bold !important;
  }
.second-primary{
    background-color: #d93128 !important;
}


.homecard1{
  //  background: #0f7eee;
  background: #fee8ca;
    color:#fff;
    ion-card-title{color:#feb8a0;}
    ion-card-content{font-size: 16px;}

}
.homecard2{
    background: #ffb12a;
    color:#000;
    ion-card-title{color:#000;}
    ion-card-content{font-size: 16px;;}

}
.homecard3{
    background: #102950;
    color:#fff;
    ion-card-title{color:#fff;}
    ion-card-content{font-size: 16px;;}

}
.homecard4{
    background: #fff;
    color:#000;
    ion-card-title{color:#000;}
    ion-card-content{font-size: 16px;;}

}
.text-margin{margin:0 10%}


.pop-over-style{
ion-popover {
	// --width: 500px !important;
    --width: 100% !important;
}
}

.cropper-point.point-e , .cropper-point.point-ne {
    right: -7px!important;
    width: 15px !important;
    height: 15px !important;
}
 .cropper-point.point-ne {
     top :-7px!important;
 }

.cropper-point.point-n, .cropper-point.point-nw {
    top: -7px !important;
    width: 15px!important;
    height: 15px!important;
}

.cropper-point.point-nw {
    left: -7px !important;
}


.cropper-point.point-s , .cropper-point.point-se {
    bottom: -7px!important;
    width: 15px!important;
    height: 15px!important;
}
.cropper-point.point-se {
    right :-7px !important;
}

.cropper-point.point-w , .cropper-point.point-sw {
    left: -7px!important;
   width: 15px!important;
    height: 15px!important;
}

.cropper-point {
    background-color: red !important;
}


.cropper-view-box {
    outline: 1px solid red;
    outline-color: red;
}

.cropper-point.point-sw {
    bottom: -7px!important;
}

.custom-input {
    border: 1px solid #000000;
    border-radius: 30px;
    padding-left: 10px;
    width: 80%;
    display: flex;
    height: 50px;
    margin: auto;
}

.margin-top-5{
    margin-top: 5%;
}


.uppercase-text {
    text-transform: uppercase;
}

.margin-bottom-5{
    margin-bottom: 5%;
}

